<template>
  <!-- 大宗商品查看 -->
  <div class="productView body-buju">
    <h2>
      <span class="top-font"></span>大宗商品查看
    </h2>
    <el-table :data="tableData3" border style="width: 100%"
      :header-cell-style="{ 'background-color': '#F4F4F4', color: '#999999' }">
      <!-- <el-table-column align="left" prop="date" label="流水号" width="120"> </el-table-column> -->
      <el-table-column align="center" prop="name" label="品名类" width="120">
      </el-table-column>
      <el-table-column align="center" prop="weight" label="重量" width="120">
      </el-table-column>
      <el-table-column align="center" prop="offerTime" label="报价时间" width="140">
      </el-table-column>
      <el-table-column align="center" prop="price" label="报价/单价/t" width="120">
      </el-table-column>
      <el-table-column align="center" prop="status" label="状态" width="120">
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="130">
        <template slot-scope="scope">
          <el-button @click="bulkView(scope.row)" size="small" v-if="`${scope.row.statusNum}` === '2'" type="text">
            下载中标通知书</el-button>
          <el-button @click="goDetail(scope.row)" size="small" class="buttonText" type="text">详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { commodityWebList } from "@/api/personalCenter";
import { permissionMixin } from "@/mixin";

export default {
  data() {
    return {
      tableData3: [
        {
          date: "202001",
          name: "破碎料",
          address: "10t",
          num: "2010.10.25",
          price: 500,
          state: "已中标",
        },
      ],
      searhQuery: {
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  created() {
    this.commodityWebList();
  },
  mixins: [permissionMixin],
  methods: {
    async commodityWebList() {
      try {
        const { success, result } = await commodityWebList({
          pageindex: 1,
          pagesize: 10,
        });
        if (success === true) {
          console.log(result);
          this.tableData3 = result.commodityWebVms;
        }
      } catch {}
    },
    bulkView(row) {
      this.$store
        .dispatch("productView/downlodaFile", { id: row.id })
        .then((res) => {
          this.downlodaFile({
            data: res,
            type: "pdf",
            name: "中标通知书",
          });
        });
    },
    goDetail(row) {
      this.queryPermisUser(() => {
        this.$router.push({
          path: "/bulkCommodity/details",
          query: {
            id: row.id,
          },
        });
      }, true);
    },
  },
};
</script>

<style lang="less" scoped>
.body-buju {
  float: left;
  margin-left: 30px; /* no */
  h2 {
    margin: 0 0 30px 10px; /* no */
  }
}
.top-font {
  width: 4px; /* no */
  height: 15px; /* no */
  background: #24a2aa;
  border-radius: 2px; /* no */
  display: inline-block;
  margin-right: 10px; /* no */
}
.buttonText {
  color: #999999;
}
.buttonText:hover {
  text-decoration: underline;
}
.productView {
  /deep/.el-table__fixed-right {
    height: 100% !important;
  }
}
</style>
