var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body-buju"},[_c('div',[_vm._m(0),_c('div',{staticClass:"top-right"},[_c('el-input',{staticClass:"search-input",attrs:{"placeholder":"请输入名称"},on:{"keypress":event => {
            if (event.keyCode == 13) return false;
          }},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getScrapList(1)}},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}},[_c('i',{staticClass:"el-icon-search",attrs:{"slot":"suffix"},on:{"click":_vm.handleIconClick},slot:"suffix"})]),_c('el-button',{staticClass:"top-search-btn",on:{"click":_vm.handleIconClick}},[_vm._v("查询 ")])],1),_c('span',{staticClass:"top-continue",on:{"click":_vm.handleContinue}},[_vm._v("继续报废车辆")])]),_c('el-table',{staticStyle:{"width":"850px"},attrs:{"data":_vm.scrapList,"border":"","header-cell-style":{
      'background-color': '#F4F4F4',
      color: '#999999'
    }}},[_c('el-table-column',{attrs:{"align":"center","prop":"platenumber","label":"车牌号","width":"120"}}),_c('el-table-column',{attrs:{"align":"center","prop":"carOwner","label":"姓名","width":"120"}}),_c('el-table-column',{attrs:{"align":"center","prop":"carType","label":"车辆类型","width":"100"}}),_c('el-table-column',{attrs:{"align":"center","prop":"creationTime","label":"申请时间","width":"130"}}),_c('el-table-column',{attrs:{"align":"center","prop":"carCode","label":"工单号"}}),_c('el-table-column',{attrs:{"align":"center","prop":"auditStatus","label":"状态","width":"120"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.goScrapDetail(scope.row)}}},[_vm._v("查看")])]}}])})],1),_c('div',{staticClass:"bottom-pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":10,"total":_vm.scrapListTotal},on:{"current-change":_vm.handleScrapChange}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span',{staticClass:"top-font"}),_vm._v("报废汽车查看 ")])
}]

export { render, staticRenderFns }