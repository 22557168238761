<template>
  <!-- 收货地址 -->
  <div class="body-buju">
    <h2><span class="top-font"></span>收货地址维护
    </h2>
    <el-form ref="shop" :model="shop" label-width="80px">
      <el-form-item label="收货人" prop="Name" :rules="[{ required: true, message: '请输入收货人名称'}]">
        <el-input class="from-item-input" placeholder="请输入收货人名称" v-model="shop.Name"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="Phone" :rules="[
        {required: true, message: '电话不能为空',trigger: ['blur', 'change']},
        {  validator: (rule, value, callback)=>{checkPhone(rule, value, callback)},   trigger: ['blur', 'change']  }
       ]">
        <el-input class="from-item-input" placeholder="请输入11位手机号码" v-model="shop.Phone">
        </el-input>
      </el-form-item>
      <el-form-item label="地区" prop="AreaId" :rules="[{ required: true, message: '请选择地区'}]">
        <el-cascader class="from-item-input" v-model="shop.AreaId" :options="areas"></el-cascader>
      </el-form-item>
      <el-form-item lable="详细地址" prop="Address" :rules="[{ required: true, message: '请输入详细地址'}]">
        <el-input class="from-item-input" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }"
          placeholder="如选择不到您的地区，请在此处详细描述" v-model="shop.Address"></el-input>
      </el-form-item>
      <el-form-item prop="IsDefault">
        <p>
          <el-checkbox v-model="shop.IsDefault">设为默认收货地址</el-checkbox>
        </p>
      </el-form-item>
      <el-form-item>
        <el-button class="add-btn" @click="onSubmit">添加新地址
        </el-button>
        <el-button class="empty-btn" @click="resetQuery()">清空</el-button>
      </el-form-item>
    </el-form>

    <el-table :row-style="selectedstyle" :row-class-name="tableRowClassName" :data="areaList" border
      :header-cell-style="{'background-color': '#F4F4F4',color: '#999999',}">
      <el-table-column align="center" prop="name" label="收货人" width="100">
      </el-table-column>
      <el-table-column align="center" prop="address" label="收货地址" width="180">
      </el-table-column>
      <el-table-column align="center" prop="phone" label="手机/电话" width="180">
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="225">
        <template slot-scope="adress">
          <el-button @click="handleClickUpdate(adress.row)" type="text" size="small"><i
              class="el-icon-edit el-icon-tubiao"></i>
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button @click="handleClickDelete(adress.row)" type="text" size="small"><i
              class="el-icon-close el-icon-tubiao"></i>
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button v-if="adress.row.isDefault == false" @click="handleClickDefault(adress.row)" type="text"
            size="small" style="color: #0D5ADB;">设为默认</el-button>
          <el-button v-if="adress.row.isDefault == true" type="text" size="small" style="color: #0D5ADB;">默认地址
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
      <el-form :rules="rules" ref="shop" :model="updateShop" label-width="80px">
        <el-form-item label="收货人" prop="Name">
          <el-input class="from-item-input" v-model="updateShop.Name"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="Phone">
          <el-input class="from-item-input" placeholder="请输入11位手机号码" v-model="updateShop.Phone"></el-input>
        </el-form-item>
        <el-form-item label="地区" prop="AreaId">
          <el-cascader class="from-item-input" v-model="updateShop.AreaId" :options="areas"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="Address">
          <el-input class="from-item-input" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }"
            placeholder="如选择不到您的地区，请在此处详细描述" v-model="updateShop.Address"></el-input>
        </el-form-item>
        <el-form-item prop="IsDefault">
          <p>
            <el-checkbox v-model="updateShop.IsDefault">设为默认收货地址</el-checkbox>
          </p>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="model-submit-btn" @click="saveUpdate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  shoppingAddressList,
  createShoppingAddress,
  deleteShoppingAddress,
  getAreaList,
  defaultAddress,
  getShoppingAddressDetails,
  updateShoppingAddress,
} from "@/api/personalCenter";
export default {
  data() {
    return {
      updateShop: {
        Name: null,
        AreaId: null,
        Address: null,
        Phone: null,
        IsDefault: false,
        id: null,
      },
      shop: {
        Name: null,
        AreaId: null,
        Address: null,
        Phone: null,
        IsDefault: false,
      },
      areas: [],
      areaList: [],
      rowId: null,
      dialogFormVisible: false,
      rules: {},
      getIndex: "",
    };
  },
  created() {
    this.getAreaList();
    this.shoppingAddressList();
  },
  methods: {
    checkPhone(rule, value, callback) {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    },
    async defaultAddress(data) {
      try {
        const { success } = await defaultAddress(data);
        if (success === true) {
          this.$message.success("此地址已设为默认地址");
          this.shoppingAddressList();
        }
      } catch {}
    },
    async shoppingAddressList() {
      try {
        const { success, result } = await shoppingAddressList({
          PageIndex: 1,
          PageSize: 10,
        });
        if (success === true) {
          this.areaList = [];
          this.areaList = result.shoppingAddressVms;
        }
      } catch {}
    },
    async getAreaList() {
      try {
        const { success, result } = await getAreaList();
        if (success === true) {
          this.areas = result;
        }
      } catch {}
    },
    async updateShoppingAddress(data) {
      try {
        const { success } = await updateShoppingAddress(data);
        if (success === true) {
          this.$message.success("修改收货信息成功");
          this.dialogFormVisible = false;
          this.areaList = [];
          this.shoppingAddressList();
        }
      } catch {}
    },
    async deleteShoppingAddress(data) {
      try {
        const { success, result } = await deleteShoppingAddress(data);
        if (success === true) {
          this.$message.success("删除成功");
          this.areaList = [];
          this.shoppingAddressList();
        }
      } catch {}
    },
    async createShoppingAddress(data) {
      try {
        const { success, result } = await createShoppingAddress(data);
        if (success === true) {
          this.$message.success("新增地址成功");
          this.shoppingAddressList();
          this.resetForm();
        }
      } catch {}
    },
    async getShoppingAddressDetails(data) {
      try {
        const { success, result } = await getShoppingAddressDetails(data);
        // this.resetForm()
        Object.assign(this.updateShop, {
          Name: result.name,
          AreaId: [
            result.area.provinceCode,
            result.area.countyCode,
            String(result.area.id),
          ],
          Address: result.address,
          Phone: result.phone,
          IsDefault: result.isDefault,
          id: this.rowId,
        });
      } catch {}
    },
    async onSubmit() {
      console.log(1111);
      console.log(this.shop.AreaId);
      this.$refs["shop"].validate((valid) => {
        const formData = new FormData();
        formData.append("Name", this.shop.Name);
        formData.append("AreaId", Number(this.shop.AreaId.pop()));
        formData.append("Address", this.shop.Address);
        formData.append("Phone", this.shop.Phone);
        formData.append("IsDefault", this.shop.IsDefault);
        this.createShoppingAddress(formData);
      });
    },
    resetQuery() {
      this.resetForm();
    },
    handleClickDelete(row) {
      // this.deleteShoppingAddress(row.id)
      this.$confirm("确认删除该地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            this.deleteShoppingAddress(row.id);
          } else {
            console.log("按下 取消");
          }
        },
      });
    },
    resetForm() {
      this.$refs.shop.resetFields();
      this.$refs.updateShop.resetFields();
    },
    handleClickUpdate(row) {
      this.rowId = row.id;
      this.dialogFormVisible = true;
      this.getShoppingAddressDetails(row.id);
    },
    selectedstyle({ row, rowIndex }) {
      if (this.getIndex === rowIndex) {
        return {
          color: "#24A2AA",
        };
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    handleClickDefault(row) {
      this.defaultAddress(row.id);
      console.log(row);
      this.getIndex = row.index;
    },
    saveUpdate() {
      const formData = new FormData();
      formData.append("id", Number(this.updateShop.id));
      formData.append("Name", this.updateShop.Name);
      formData.append("Address", this.updateShop.Address);
      formData.append("AreaId", Number(this.updateShop.AreaId.pop()));
      formData.append("Phone", this.updateShop.Phone);
      formData.append("IsDefault", this.shop.IsDefault);
      this.updateShoppingAddress(formData);
    },
  },
};
</script>

<style lang="less" scoped>
.body-buju {
  float: left;
  margin-left: 30px; /* no */
  h2 {
    margin: 0 0 30px 10px; /* no */
  }
}
.top-font {
  width: 4px; /* no */
  height: 15px; /* no */
  background: #24a2aa;
  border-radius: 2px; /* no */
  display: inline-block;
  margin-right: 10px; /* no */
}
.from-item-input {
  width: 63%;
}
.add-btn {
  background: #24a2aa;
  color: white;
  width: 180px; /* no */
  margin-right: 20px; /* no */
}
.empty-btn {
  width: 180px; /* no */
  background: #eeeeee;
}
.el-icon-tubiao {
  font-size: 18px; /* no */
  color: #aaaaaa;
}
.model-submit-btn {
  background: #24a2aa;
  color: white;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  color: #24a2aa !important;
  background: #24a2aa !important;
}

/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #24a2aa !important;
}
</style>
