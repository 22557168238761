<template>
  <!-- 密码设置 -->
  <div class="body-buju">
    <h2>
      <span class="top-font"></span>密码设置
    </h2>
    <div class="right">
      <el-form :rules="rules" ref="update" :model="update" label-width="200px" class="body-from">
        <el-form-item label="旧密码" prop="CurrentPassword" :rules="[{ required: true, message: '请输入旧密码' }]">
          <el-input class="from-item-input" placeholder="请输入旧密码" v-model="update.CurrentPassword" show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="NewPassword" :rules="[{ required: true, message: '请输入密码' }]">
          <el-input class="from-item-input" placeholder="请输入密码" v-model="update.NewPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="请再次确认密码" prop="passwordAgin" :rules="[{ required: true, message: '请再次确认密码' }]">
          <el-input class="from-item-input" placeholder="请再次确认密码" v-model="update.passwordAgin" show-password>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="submit-btn" @click="saveNewPassword">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { updatePassword } from "@/api/personalCenter";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      update: {
        CurrentPassword: "",
        NewPassword: "",
        passwordAgin: "",
      },
      rules: {},
    };
  },
  created() {},
  methods: {
    async updatePassword(param) {
      try {
        const { success, result } = await updatePassword(param);
        if (result.result == true) {
          Cookies.remove("userInfo");
          this.$message.success("修改成功,请重新登录");
          this.$router.push({
            path: "/register/login",
          });
          Cookies.remove("userInfo");
        } else {
          this.$message.error(result.msg);
        }
      } catch {}
    },
    async saveNewPassword() {
      this.$refs["update"].validate((valid) => {
        if (
          this.update.CurrentPassword !== "" &&
          this.update.NewPassword !== "" &&
          this.update.passwordAgin !== ""
        ) {
          if (this.update.NewPassword == this.update.passwordAgin) {
            let param = {
              NewPassword: this.update.NewPassword,
              CurrentPassword: this.update.CurrentPassword,
            };
            this.updatePassword(param);
          } else {
            this.$message.error("请确认新密码");
          }
        }
      });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.body-buju {
  float: left;
  margin-left: 30px; /* no */
  h2 {
    margin: 0 0 30px 10px; /* no */
  }
}
.top-font {
  width: 4px; /* no */
  height: 15px; /* no */
  background: #24a2aa;
  border-radius: 2px; /* no */
  display: inline-block;
  margin-right: 10px; /* no */
}
.body-from {
  padding: 29px 30px 30px 30px; /* no */
  width: 800px; /* no */
}
.from-item-input {
  width: 50%;
}
.submit-btn {
  background: #24a2aa;
  color: white;
  width: 25%;
  height: 40px; /* no */
  margin-right: 10px; /* no */
}
</style>
