<template>
  <!-- 订单列表 -->
  <div class="body-buju">
    <h2>
      <span class="top-font"></span>零配件订单
    </h2>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部订单" name="null"></el-tab-pane>
      <el-tab-pane label="待付款" name="0"></el-tab-pane>
      <el-tab-pane label="待发货" name="1"></el-tab-pane>
      <el-tab-pane label="待收货" name="2"></el-tab-pane>
      <el-tab-pane label="待评价" name="3"></el-tab-pane>
      <el-tab-pane label="已完成" name="4"></el-tab-pane>
      <el-tab-pane label="已取消" name="5"></el-tab-pane>
    </el-tabs>
    <div>
      <div v-for="(item, index) in orderList" :key="index">
        <div class="shopDetail">
          <span>订单标号: {{ item.orderNo }}</span>
          <span>支付方式: {{ item.payMethod }}</span>
          <span>收货电话: {{ item.phone }}</span>
          <span>订单状态: {{ item.productOrderStatus }}</span>
          <span>收货人姓名: {{ item.userName }}</span>
        </div>
        <el-table :data="item.orderVms" border :header-cell-style="{
            'background-color': '#F4F4F4',
            color: '#999999'
          }">
          <el-table-column label="商品信息" width="300">
            <template slot-scope="scope">
              <div class="shop-information" @click="handClick(scope.row.productId)">
                <img :src="scope.row.filePath" class="shop-img" />
                <span class="shop-name">{{ scope.row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="数量" prop="numCount"></el-table-column>
          <el-table-column align="center" label="单价" prop="perPrice"></el-table-column>
          <el-table-column align="center" label="总价" prop="price"></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="list" class="shopDetailBtns">
              <partsOrder-btn :list="list" :partsOrderBtnData="item" @handleConfirm="handleConfirm" :isList="true" />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <p @click="jump" class="bottom-font" v-if="orderList.length == 0">
        没有有效的订单 还去逛逛么~
      </p>
    </div>
  </div>
</template>

<script>
import { orderWebList } from "@/api/personalCenter";
import partsOrderBtn from "../components/button";
export default {
  data() {
    return {
      activeName: "null",
      textarea: "",
      orderList: [],
    };
  },
  components: {
    partsOrderBtn,
  },
  created() {
    this.orderWebList({
      ProductOrderStatus: null,
    });
  },
  methods: {
    async orderWebList(data) {
      try {
        const { code, result } = await orderWebList(data);
        this.orderList = result.productListPageVms;
        console.log(this.orderList);
      } catch (error) {}
    },
    handleConfirm(ProductOrderStatus) {
      this.orderWebList({
        ProductOrderStatus,
      });
    },
    handClick(productId) {
      this.$router.push({
        path: "/sparePartsMall/productDetails",
        query: {
          id: productId,
        },
      });
      this.$emit("handClick");
    },
    handleClick() {
      this.orderList = [];
      if (this.activeName == null) {
        let param = {
          ProductOrderStatus: "",
        };
        this.orderWebList();
      } else {
        this.orderWebList({
          ProductOrderStatus: this.activeName,
        });
      }
    },
    jump() {
      this.$router.push({
        path: "/sparePartsMall/sparePartsMall",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.body-buju {
  float: left;
  margin-left: 30px; /* no */
  h2 {
    margin: 0 0 30px 10px; /* no */
  }
}
.top-font {
  width: 4px; /* no */
  height: 15px; /* no */
  background: #24a2aa;
  border-radius: 2px; /* no */
  display: inline-block;
  margin-right: 10px; /* no */
}
.shopDetail {
  width: 835px; /* no */
  background-color: #f4f4f4;
  color: #999999;
  margin-bottom: 10px; /* no */
  padding: 10px; /* no */
  display: flex;
  justify-content: space-between;
}
.shop-img {
  width: 100px; /* no */
  height: 100px; /* no */
  margin-right: 10px; /* no */
}
.bottom-font {
  text-align: center;
  line-height: 100px; /* no */
  font-size: 16px; /* no */
  color: #999999;
  cursor: pointer;
}
.shop-information {
  display: flex;
  cursor: pointer;

  span {
    align-self: center;
  }
}

.buttonText {
  display: block;
  margin: 0 auto !important;
}

.buttonText:hover {
  text-decoration: underline;
}

/deep/.evaluation-msgbox {
  .stars {
    padding: 30px 0; /* no */
  }
}
</style>
