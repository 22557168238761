<template>
  <div style="background-color: #f8fcff;">
    <el-card class="box-card">
      <div style="float: left;">
        <el-row class="tac">
          <el-col :span="20">
            <div class="left-top">
              <img class="left-top-img" :src="userPhoto" alt="" />
              <p>{{ name }}</p>
            </div>
            <el-menu :default-active="active" class="el-menu-vertical-demo" @select="handClick" @open="handleOpen"
              @close="handleClose" background-color="#fdfeff" text-color="#333333" active-text-color="#24a2aa">
              <el-menu-item index="1">
                <i class="el-icon-film"></i>
                <span slot="title">报废汽车查看</span>
              </el-menu-item>
              <el-menu-item index="2">
                <i class="el-icon-tickets"></i>
                <span slot="title">零配件订单</span>
              </el-menu-item>
              <el-menu-item index="3">
                <i class="el-icon-suitcase-1"></i>
                <span slot="title">大宗商品申请查看</span>
              </el-menu-item>
              <el-menu-item index="4">
                <i class="el-icon-lock"></i>
                <span slot="title">密码设置</span>
              </el-menu-item>
              <el-menu-item index="5">
                <i class="el-icon-setting"></i>
                <span slot="title">收货地址维护</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <scrapView v-if="value == '1'"></scrapView>
      <!-- 订单列表 -->
      <sparePartsOrder v-if="value == '2'" @handClick="handClick(6)"></sparePartsOrder>
      <!-- 大宗商品查看 -->
      <productView v-if="value == '3'"></productView>
      <!-- 密码设置 -->
      <passwordSettings v-if="value == '4'"></passwordSettings>
      <!-- 收货地址 -->
      <shippingAddress v-if="value == '5'"></shippingAddress>
      <shipping-details v-if="value == '6'"></shipping-details>
    </el-card>
  </div>
</template>

<script>
import scrapView from "@/views/personalCenter/personalCenterList/scrapView.vue";
import sparePartsOrder from "@/views/personalCenter/personalCenterList/sparePartsOrder.vue";
import productView from "@/views/personalCenter/personalCenterList/productView.vue";
import passwordSettings from "@/views/personalCenter/personalCenterList/passwordSettings.vue";
import shippingAddress from "@/views/personalCenter/personalCenterList/shippingAddress.vue";
import shippingDetails from "@/views/personalCenter/orderList/details.vue";
import Cookies from "js-cookie";

export default {
  name: "adressMaintain",
  components: {
    scrapView,
    sparePartsOrder,
    productView,
    passwordSettings,
    shippingDetails,
    shippingAddress,
  },
  data() {
    return {
      dialogFormVisible: false,
      searchKey: "",
      scrapListTotal: 0,
      checked: false,
      optionA4: [
        {
          value: "0",
          label: "全部状态",
        },
        {
          value: "1",
          label: "正常运行",
        },
        {
          value: "2",
          label: "离线或故障",
        },
        {
          value: "3",
          label: "超标报警",
        },
      ],
      active: 1,
      activeName: "null",
      update: {
        CurrentPassword: "",
        NewPassword: "",
        passwordAgin: "",
      },
      form: {
        name: "",
        phone: "",
        sparePhone: "",
        address: "",
        addressInfo: "",
        postcode: "",
        type: [],
        status: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入11位手机号码",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请选择地址",
            trigger: "blur",
          },
        ],
      },
      areas: [],
      value: "",
      name: "",
      scrapList: [],
      tableData2: [
        {
          date: "固特异轮胎 御乘SUV EFFICIENTGRIP SUV 225/65R17 102H Goodyear",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          num: 13123123,
          path: "/img/shangpin.png",
        },
        {
          date: "固特异轮胎 御乘SUV EFFICIENTGRIP SUV 225/65R17 102H Goodyear",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          num: 13123123,
          path: "/img/shangpin.png",
        },
        {
          date: "固特异轮胎 御乘SUV EFFICIENTGRIP SUV 225/65R17 102H Goodyear",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          num: 13123123,
          path: "/img/shangpin.png",
        },
        {
          date: "固特异轮胎 御乘SUV EFFICIENTGRIP SUV 225/65R17 102H Goodyear",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          num: 13123123,
          path: "/img/shangpin.png",
        },
      ],
      rowId: null,
      tableData3: [
        {
          date: "202001",
          name: "破碎料",
          address: "10t",
          num: "2010.10.25",
          price: 500,
          state: "已中标",
        },
      ],
      searchQuery: {},
      updateShop: {
        Name: null,
        AreaId: null,
        Address: null,
        Phone: null,
        IsDefault: null,
        id: null,
      },
      shop: {
        Name: null,
        AreaId: null,
        Address: null,
        Phone: null,
        IsDefault: null,
      },
      areaList: [],
      searhQuery: {
        pageSize: 10,
        pageIndex: 1,
      },
      name: "",
      userPhoto: "",
      orderList: [],
      userDate: "",
    };
  },
  created() {
    if (!Cookies.get("userInfo")) {
      this.$router.push({
        path: "/register/login",
      });
    }
    if (Cookies.get("userInfo") !== undefined) {
      console.log(Cookies.get("userInfo"));
      this.userDate = JSON.parse(Cookies.get("userInfo"));
      this.name = this.userDate.name;
      this.userPhoto = this.userDate.userPhoto;
    }
    this.value = this.$route.query.value;
    this.active = this.$route.query.value;
    if (this.value == undefined) {
      this.value = 1;
    }
    if (this.active == undefined) {
      this.active = "1";
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handClick(index) {
      this.value = index;
      switch (index) {
        case 1:
          //报废汽车查看
          // this.getScrapList();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  width: 1180px; /* no */
  margin: 20px auto; /* no */
  display: flex;
}
.left-top {
  margin-left: 20px;
  text-align: center;
  margin-bottom: 40px;
}
.left-top-img {
  width: 72px; /* no */
  height: 72px; /* no */
  object-fit: cover;
  border-radius: 50%;
}
.el-menu-vertical-demo {
  width: 240px; /* no */
}
em {
  font-style: normal;
}
/deep/.el-pagination {
  /deep/.active {
    background: #24a2aa !important;
  }
}
.search-input {
  /deep/.el-input__inner {
    height: 100% !important;
    border: 1px solid #24a2aa; /* no */
  }
}
/deep/.el-menu-item.is-active {
  background: linear-gradient(180deg, #fff, #e4f4f5 50%);
  span {
    background: linear-gradient(
      180deg,
      #181a7d 0%,
      #30c9cd 71.4%,
      #5bb960 100%
    );
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: white;
  background: black;
}

/deep/.el-button + .el-button {
  margin: 0;
}
/deep/.el-card__body {
  width: 100%;
}
/deep/.el-tabs__item {
  margin-right: 20px; /* no */
  background-color: rgb(244, 244, 244);
}

/deep/.el-tabs__active-bar {
  background: none;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: white;
}

/deep/.el-tabs__item.is-active {
  color: white;
  background: black;
}

/deep/.el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important; /* no */
}

/deep/.el-tabs__item.is-top:last-child {
  padding-right: 20px !important; /* no */
}
/deep/.el-menu-item:hover {
  // background: linear-gradient(180deg, #fff, #e4f4f5 50%);
  background-color: white !important;
  span {
    background: linear-gradient(
      180deg,
      #181a7d 0%,
      #30c9cd 71.4%,
      #5bb960 100%
    );
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
// /deep/.el-textarea__inner {
//   letter-spacing: -2px;
// }
.shopDetail {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.shop-img {
  width: 100px; /* no */
  height: 100px; /* no */
}
.shop-information {
  display: flex;
  align-items: center;
}
</style>
