<template>
  <!-- 报废汽车查看 -->
  <div class="body-buju ">
    <div>
      <h2>
        <span class="top-font "></span>报废汽车查看
      </h2>
      <div class="top-right">
        <el-input class="search-input" v-model="searchKey" placeholder="请输入名称" @keypress="
            event => {
              if (event.keyCode == 13) return false;
            }
          " @keyup.enter.native="getScrapList(1)">
          <i class="el-icon-search" slot="suffix" @click="handleIconClick">
          </i>
        </el-input>
        <el-button @click="handleIconClick" class="top-search-btn">查询
        </el-button>
      </div>
      <span @click="handleContinue" class="top-continue">继续报废车辆</span>
    </div>
    <el-table :data="scrapList" border style="width: 850px" :header-cell-style="{
        'background-color': '#F4F4F4',
        color: '#999999'
      }">
      <el-table-column align="center" prop="platenumber" label="车牌号" width="120">
      </el-table-column>
      <el-table-column align="center" prop="carOwner" label="姓名" width="120">
      </el-table-column>
      <el-table-column align="center" prop="carType" label="车辆类型" width="100">
      </el-table-column>
      <el-table-column align="center" prop="creationTime" label="申请时间" width="130">
      </el-table-column>
      <el-table-column align="center" prop="carCode" label="工单号">
      </el-table-column>
      <el-table-column align="center" prop="auditStatus" label="状态" width="120">
      </el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="goScrapDetail(scope.row)" type="text" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom-pagination">
      <el-pagination @current-change="handleScrapChange" background layout="prev, pager, next" :page-size="10"
        :total="scrapListTotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getScrapList } from "@/api/personalCenter";
import { mapState } from "vuex";

export default {
  data() {
    return {
      searchKey: "",
      scrapList: [],
      scrapListTotal: 0,
      searchQuery: {},
    };
  },
  computed: {
    ...mapState({
      get_ScrapList: (state) => {
        return state.personalCenter.get_ScrapList;
      },
    }),
  },
  created() {
    this.getScrapList(1);
  },
  methods: {
    async getScrapList(index) {
      // console.log(this.searchKey, "this.searchKey");
      try {
        this.$store
          .dispatch("personalCenter/getScrapList", {
            FindKeyWords: this.searchKey,
            PageSize: 10,
            PageIndex: index,
          })
          .then(() => {
            this.scrapList = this.get_ScrapList.requreCarWebVms;
            this.scrapListTotal = this.getScrapList.totalCount;
          });
        // const params = Object.assign(this.searchQuery, {
        //   FindKeyWords: this.searchKey,
        //   PageSize: 10,
        //   PageIndex: index,
        // });
        // const res = await getScrapList(params);
        // // console.log(res);
        // this.scrapList = res.result.requreCarWebVms;
        // this.scrapListTotal = res.result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    handleIconClick() {
      this.getScrapList(1);
    },
    handleScrapChange(index) {
      this.getScrapList(index);
      // console.log(index);
    },
    handleContinue() {
      this.$router.push({
        path: "/scrapGuide/scrapGuide",
      });
    },
    goScrapDetail(row) {
      this.$router.push({
        path: "/personalCenter/scrapDetails",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.body-buju {
  float: left;
  margin-left: 30px; /* no */
  h2 {
    margin: 0 0 30px 10px; /* no */
    float: left;
  }
}
.top-font {
  width: 4px; /* no */
  height: 15px; /* no */
  background: #24a2aa;
  border-radius: 2px; /* no */
  display: inline-block;
  margin-right: 10px; /* no */
}
.top-right {
  width: 300px; /* no */
  height: 40px; /* no */
  float: right;
}
.el-icon-search {
  font-size: 16px; /* no */
  line-height: 40px; /* no */
}
.top-search-btn {
  background: #24a2aa;
  color: white;
  margin-left: 10px; /* no */
  height: 40px; /* no */
}
.top-continue {
  cursor: pointer;
  float: right;
  display: inline-block;
  width: 100px; /* no */
  margin-right: 17px; /* no */
  border: 1px solid #24a2aa;
  height: 38px; /* no */
  border-radius: 10px; /* no */
  color: #24a2aa;
  text-align: center;
  line-height: 40px; /* no */
  margin-top: 5px; /* no */
}
.bottom-pagination {
  text-align: center;
  margin-top: 10px; /* no */
}
.search-input {
  margin-top: 5px; /* no */
  height: 40px; /* no */
  width: 200px; /* no */
  /deep/.el-input__inner:focus {
    border-color: #2ec4cd;
  }
  /deep/.el-input__inner:hover {
    border-color: #2ec4cd;
  }
}
</style>
